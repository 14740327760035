.form__Container {
  background-color: #262626;
  color: #dcdcdc;
}
.form {
  max-width: 800px;
  margin: 0em auto;
  font-family: "Times New Roman", Times, serif;
}

.form h1 {
  text-align: center;
  font-weight: 900;
  padding-bottom: 1em;
}

.form h3 {
  font-weight: 600;
  margin-bottom: 0.5em;
}

.searchCustomerBtn {
  text-align: center;
  border-radius: 1em !important;
  width: 2.5em;
  padding: 0.3em !important;
  margin: 0.3em 0.5em;
}

.form__customerSec,
.form__product,
.form__misc {
  border: 1px solid lightgray;
  border-radius: 0.5em;
  padding: 0.6em;
  margin: 0.3em 0.5em 2em 1em;
}

.form__displayColumn {
  display: flex;
  flex-direction: column;
  width: 80%;
}

.app__InputSec p {
  margin-bottom: 0.2em;
}

.app__InputSec input {
  padding-left: 0.3em;
  width: 80%;
  border-radius: 0.3em;
  border: 2px solid rgb(196, 196, 196);
}
.app__InputSec input:focus {
  outline: none;
}

.form select {
  width: 80%;
  font-size: 1.3em;
  cursor: pointer;
}

.form__productAddBtn {
  display: flex;
  justify-content: center;
}

.form__productAddBtn button {
  background-color: transparent;
  color: #ffffff;
  border: 2px solid #ffffff;
  border-radius: 0.5em;
  height: 2.5em;
  width: 10em;
  padding: 0;
  margin: 1.5em 0 1em 0;
  font-weight: 600;
}

.form__productAddBtn button:hover {
  background-color: #ffffff;
  color: #262626;
}

.form__productAddBtn button:focus {
  outline: none;
}

.form__submitBtn {
  display: flex;
  justify-content: center;
}

.form__submitBtn button {
  width: 10em;
  height: 2.5em;
  margin: 0.5em 0 8em 0;
  background-color: yellowgreen;
  border: none;
  border-radius: 0.5em;
  text-transform: uppercase;
  font-weight: 600;
}

.form__submitBtn button:hover {
  background: rgb(219, 255, 147);
}

.form__alert {
  width: 90%;
  padding: 0;
  margin: 0 auto 0.6em auto;
  height: 2em;
}
