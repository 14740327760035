.billPage {
  background-color: #fff;
  color: #000000;
  margin: 2em;
  padding: 1em;
  position: relative;
}

.bg__image img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 70%;
  opacity: 0.1;
  transform: translate(-50%, -50%);
  background-origin: content-box;
  background-position: center;
  background-size: 50% 80%;
  filter: grayscale(100%);
}

.billPage__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
  margin-top: 2em;
}

.billPage__header h1 {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-weight: 700;
}

.billPage__header h2 {
  color: #535353;
  margin-right: 1em;
}

.billPage__details {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3.5em;
}

.billPage__customer {
  margin: 1em 4em 7em 1em;
}

table {
  width: 100%;
  margin-bottom: 3em;
}

table,
tr,
th,
td {
  border: 1px solid #000000;
}

th,
td {
  padding: 5px;
}

tr:nth-child(even) {
  background-color: #e0e0e0;
}

.billPage__terms {
  margin: 0.2em 0.2em 7em 2em;
}

.billPage__terms li {
  font-weight: 500;
}

.billPage__signContainer {
  display: flex;
  justify-content: flex-end;
  margin: 12em 2em 3em 1em;
}

.billPage__sign {
  font-weight: 600;
  text-align: center;
  width: 10em;
  padding-top: 1em;
  border-top: 2px dotted #000000;
}

.billPage__paymentSec {
  border: 1px solid lightgray;
  padding: 1em;
  margin: 0.5em;
  border-radius: 0.5em;
}

.billPage__amount {
  display: flex;
  padding-bottom: -0.3em;
  margin-bottom: 0;
  justify-content: space-between;
  font-weight: 700;
  border-bottom: 1px solid lightgray;
}

.billPage__amount p {
  margin-bottom: 0;
  margin-top: 0.6em;
}

@media (max-width: 648px) {
  .bg__image img {
    width: 95%;
  }

  .billPage {
    width: 100vw;
  }

  .billPage {
    font-size: 0.5em;
  }

  .billPage h1 {
    font-size: 2em;
  }

  .billPage h2,
  .billPage h6,
  .billPage h5 {
    font-size: 1.5em;
  }
}
