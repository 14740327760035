.statusProduct {
  cursor: pointer;
  max-width: 700px;
  border: 1px solid lightgray;
  border-bottom: 2px solid rgb(103, 103, 103);
  border-radius: 0.4em;
  padding: 1em;
  margin: 0.5em auto;
  transition: all 0.5s;
}

.statusProduct__header {
  display: flex;
  justify-content: space-between;
}
