.billList {
  padding: 1em;
}

.billList h1 {
  text-align: center;
  margin: 1em;
}

.billList h2 {
  text-align: center;
  width: 100%;
  padding: 1em;
  background-color: rgb(255, 175, 175);
  border-radius: 0.2em;
}

.billList__container {
  cursor: pointer;
  margin: 1em;
  text-align: center;
}

.billList__container td {
  height: 1.5em;
}

.billList__bill:hover {
  background-color: rgb(72, 72, 72);
  color: aliceblue;
}

.billList__bill {
  height: 3em;
}

.billList__buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.2em 0;
  font-size: 0.8em;
}

.billList__buttons button {
  font-weight: 100;
  text-transform: uppercase;
  border: 1px solid #8c8c8c;
  background: rgb(0, 0, 0);
  color: #fff;
  margin: 0.2em 0;
  padding: 0.2em;
  width: 6em;
}

.billList__buttons button:hover {
  background-color: #fff;
  color: #000000;
}
