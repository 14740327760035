.engPage {
  background: #262626;
  color: #fff;
  width: 100%;
  min-height: 90vh;
  overflow-x: hidden;
}

.engPage__SearchBox input {
  border-radius: 2em 0 0 2em;
  border: none;
  outline: none;
  font-size: 2em;
  height: 2em;
  width: 8em;
  padding: 0.5em;
  margin: 0.5em 0 1em 0;
}

.engPage__SearchBox input:focus,
.engPage__SearchBox button:focus {
  outline: none;
}

.engPage__SearchBox button {
  border-radius: 0 2em 2em 0;
  border: none;
  border-left: 2px solid lightgray;
  background: #fff;
  color: #262626;
  font-size: 2em;
  height: 2em;
  width: 3.5em;
  font-weight: 700;
}

.engPage__SearchBox button:hover {
  background-color: #262626;
  color: #fff;
}
.engPage__alert {
  height: 2em;
  width: 100%;
  padding: 0.1em;
}

.engPage__jobSec {
  background-color: #fff;
  color: #262626;
  border-radius: 1em;
  margin: 1em auto 1em auto;
  padding: 3em;
  height: fit-content;
  width: 80%;
}

.engPage__jobSec h3 {
  font-weight: 100;
}

.engPage__jobSec li {
  list-style: square;
  margin-left: 2em;
  border-bottom: 1px solid lightgray;
}

.engPage__modalContainer {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(42, 42, 42, 0.7);
  z-index: 99;
}

.engPage__formModal {
  display: flex;
  flex-direction: column;
  max-width: 700px;
  height: fit-content;
  border-radius: 0.5em;
  align-items: center;
  margin: 1em;
  padding: 1em;
  background-color: #fff;
  color: #262626;
  overflow-y: auto;
}

.engPage__input input {
  width: 20em;
  padding: 0.2em;
  border-radius: 0.3em;
  border: 1px solid rgb(126, 126, 126);
}

.engPage__input p {
  margin-bottom: 0;
  font-weight: 500;
}

.engPage__input select {
  margin: 0.5em;
  padding: 0.3em;
  font-weight: 500;
}

.engPage__buttons button {
  margin: 1em;
  width: 6em;
}
