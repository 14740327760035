.navBar {
  background-color: #262626;
  color: #fff;
  height: fit-content;
  padding: 10px;
  display: flex;
  margin: 0;
  justify-content: center;
}

.navBar__logo {
  display: flex;
  justify-content: center;
  margin-right: 1em;
  background-color: rgb(255, 255, 255);
  color: #262626;
  margin: 0.1em;
  border-radius: 0.2em;
  width: fit-content;
}

.navBar__logo img {
  width: 5em;
}

.navBar__logo h3 {
  text-shadow: 0 0 5px rgb(155, 155, 155);
  font-family: serif;
}

.navBar__left {
  display: flex;
}

.navBar__right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.navBar___list {
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  margin-left: 2em;
}

.navBar___list h5 {
  padding-right: 0.6em;
  /* text-transform: uppercase; */
  /* padding-top: 15px; */
}

.navBar___list a {
  color: #fff;
}

.navBar___list a:hover {
  color: lightgray;
  cursor: pointer;
}

.navBar___searchBox input {
  border: none;
  border-radius: 0.2em 0 0 0.2em;
  padding: 0.2em;
  width: 12em;
  margin: 0;
  margin-top: 0.6em !important;
  height: 2.5em;
}

.navBar___searchBox input:focus {
  outline: none;
}

.navBar___searchBox button {
  border: 1.8px solid #fff;
  background: transparent;
  color: #fff;
  margin: 0;
  margin-right: 3em !important;
  margin-top: 0.2em !important;
  height: 2.5em;
  padding: 0.1em 0.3em 0.1em 0.3em;
  border-radius: 0 0.2em 0.2em 0;
}

.navBar__signOut button {
  background: transparent;
  border: none;
  color: #fff;
}

.navBar__signOut button:hover {
  outline: none;
  color: red;
}

.navBar__stockContainer {
  position: fixed;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #262626dc;
  width: 100%;
  height: 100vh;
  z-index: 99;
}

.navBar__stock {
  position: fixed;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  border-radius: 0.5em;
  padding: 0.5em 2em 2em 2em;
  height: fit-content;
  width: fit-content;
  font-size: 1.2em;
  z-index: 999;
}

.navBar__stock h3 {
  text-align: center;
  text-transform: uppercase;
  font-weight: 100;
}

.navBar__stock button {
  margin-bottom: 0.8em;
  text-transform: uppercase;
}

.navBar__stock input,
.navBar__stock textarea {
  width: 15em;
  margin-bottom: 1em;
  padding: 0.2em;
}

.navBar__stock p {
  margin-bottom: 0;
}

.navBar__alert {
  width: 90%;
  padding: 0;
  margin: 0 auto 0.6em auto;
  height: 2em;
}

.navBar__close {
  display: flex;
  align-self: flex-end;
  margin-bottom: 0.5em;
  margin-top: 0;
  padding: 0.2em 0.4em;
  cursor: pointer;
  border-radius: 0.5em;
  font-weight: 500;
}

.navBar__close:hover {
  color: rgb(255, 79, 79);
}

.navBar__link:hover {
  cursor: pointer;
  color: lightgray;
  text-decoration: underline;
}
