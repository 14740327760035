.memoPage {
  height: fit-content;
  background-color: #fff;
  color: #000000;
  margin-left: 4em;
  margin-right: 4em;
}

.memoPage h5 {
  font-weight: 700;
  text-transform: uppercase;
}

.memoPage__header {
  text-align: center;
}

.memoPage__header h1 {
  font-size: 4em;
  padding: 0.2em;
  font-family: Georgia, "Times New Roman", Times, serif;
}

.memoPage__header h5 {
  font-weight: 800;
  margin-bottom: 2em;
}

.memoPage h4 {
  text-align: center;
  margin: 0.8em;
  margin-bottom: 3em;
  text-decoration: underline;
}

.memoPage__customer {
  margin-bottom: 2em;
}

.memoPage__customer p {
  margin: 5px;
}

.memoPage__product {
  border: 1px solid lightgray;
  border-radius: 0.5em;
  padding: 0.8em 0.8em 0 0.8em;
  margin: 0.5em;
  margin-bottom: 2em;
}

.memoPage__product ul {
  list-style-type: none;
}

.memoPage__miscs {
  display: flex;
  justify-content: space-between;
  margin: 0.2em 0.2em 2em 2em;
}

.memoPage__terms {
  margin: 0.2em 0.2em 7em 2em;
}

.memoPage__terms li {
  font-weight: 500;
}

.memoPage__signSec {
  display: flex;
  justify-content: space-between;
}

.memoPage__sign {
  margin: 1em 3em;
  padding: 0.1em 3em;
  border-bottom: 2px dotted #000000;
}

@media (max-width: 648px) {
  .memoPage {
    width: 100vw;
  }

  .memoPage {
    font-size: 0.5em;
  }

  .memoPage h1 {
    font-size: 2.5em;
  }

  .memoPage h2,
  .memoPage h6,
  .memoPage h5,
  .memoPage h4 {
    font-size: 1.5em;
  }
}

/* .memoPage__buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 3em;
  margin-bottom: 3em;
}

.memoPage__buttons button {
  font-weight: 600;
  text-transform: uppercase;
  border: 2px solid #000000;
  background: rgb(0, 0, 0);
  color: #fff;
  margin: 1em;
  padding: 0.2em;
  width: 8em;
  border-radius: 0.3em;
}

.memoPage__buttons button:hover {
  background-color: #fff;
  color: #000000;
} */
