.signin {
  width: 100%;
  background: #262626;
  color: #fff;
  height: 100vh;
}

.signin__Sec {
  background: #a8ff78;
  background: -webkit-linear-gradient(to right, #78ffd6, #a8ff78);
  background: linear-gradient(to right, #78ffd6, #a8ff78);
  color: #262626;
  display: flex;
  flex-direction: column;
  border: 1px solid lightgray;
  border-radius: 0.8em;
  width: 95%;
  height: fit-content;
  padding: 2em;
  margin: 20vh auto auto auto;
}

.signin__Sec h1 {
  text-align: center;
  font-weight: 200;
  margin-bottom: 1em;
}

.signin__alert {
  height: 2em;
  padding: 0;
  width: 100%;
}

.signin__Sec input {
  border: 1px solid rgb(160, 160, 160);
  border-radius: 0.3em;
  padding: 0.2em 1em 0.2em 1em;
  margin-bottom: 1em;
}

.signin__Sec input:focus {
  outline: none;
}

.signin__inputSec {
  display: flex;
  flex-direction: column;
}
