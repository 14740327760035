.dashboard {
  background-color: #262626;
  color: #fff;
  height: 100%;
  padding-bottom: 2em;
  overflow-x: hidden;
}

.dashboard__headerSec {
  /* border-bottom: 1px solid lightgray; */
  padding: 0em;
}

.dashboard__headerSec select {
  font-size: 1.2em;
  margin-right: 2em;
  padding: 0 8px 0 8px;
  border: none;
  border-radius: 1.2em;
  height: 2em;
  width: 7em;
}

.dashboard__headerSec select:focus {
  outline: none;
}

.dashboard__headerSec option {
  background: #262626;
  color: #fff;
  border: none;
}

.dashboard__title h2 {
  text-transform: uppercase;
  font-weight: 100;
}

.dashboard__paymentSec,
.dashboard__statusSec {
  padding: 1em;
  margin: 2em;
  border: 1px solid lightgray;
  border-radius: 0.8em;
}

.buttons {
  display: flex;
  margin-right: 1em;
}

.download {
  position: relative;
  width: 4em;
  margin: 0 3em;
}

.download img {
  width: 4em;
  margin: 0 0.5em;
  cursor: pointer;
}

.download__btn {
  position: absolute;
  bottom: -3em;
  left: 0;
  padding: 0.5em;
  border-radius: 0.3em;
  background-color: #fff;
  color: #262626;
}

#pickDate {
  font-size: 1.2em;
  padding: 0.4em;
  border-radius: 0.5em;
  cursor: pointer;
}

.dashboard__statusSec a:hover,
.dashboard__paymentSec a:hover {
  text-decoration: none;
}

.dashboard__payments,
.dashboard__status {
  background-color: #fff;
  color: #262626;
  border: 1px solid lightgray;
  text-align: center;
  padding: 1em;
  margin: 1em 0.5em;
  border-radius: 1em;
}

.dashboard__status {
  height: 10em;
}

.dashboard__payments:hover,
.dashboard__status:hover {
  background-color: #262626;
  color: #fff;
}

.dashboard__payments h3,
.dashboard__status h3 {
  font-weight: 100;
  color: rgb(72, 72, 72);
}
